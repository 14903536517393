@keyframes verticalLoop {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-800px);
  }
}

.vertical-loop-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 110px;
  width: 70%;
  /* Adjust the height as needed */
}

.vertical-loop-text {
  /* display: block; */
  height: 100px;
  margin: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 0 20px;

}

.animate {
  animation: verticalLoop 0.09s linear infinite;
}